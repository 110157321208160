@import "../../variables.scss";

.section {
  overflow: hidden;
  position: relative;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }

  &__bgImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }

  &__bgVideoPlayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }

    .vjs-poster { // implemented as bg-image
      background-size: cover;
      background-position: top center;
    }
  }

  &__bgOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &--mode-dark {
    .col {
      * {
        color: $color-white;
      }
    }
  }

  &--mode-solid-green {
    &__bg {
      background-color: $color-green;
    }
  }

  &--get-updates {
    padding-top: rem(50);
    padding-bottom: rem(50);

    h2 {
      font-size: rem(32);
      font-weight: 400;
      line-height: rem(40);
    }

    p {
      font-size: rem(15);
      line-height: rem(24);

      &.required-fields {
        @include text-small;
      }
    }

    .intro {
      @include respond-to("medium") {
        max-width: rem(282);
      }
    }
  }

  &--disclaimer {
    background-color: $color-lightgray;
    padding-top: rem(24);
    padding-bottom: rem(8);
    p {
      font-size: rem(12);
      line-height: rem(16);
    }
  }
}