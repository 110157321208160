@import '../../variables.scss';

#error404 {
  min-height: calc(100vh - #{rem(385)});
  display: flex;
  flex-direction: row;
  align-items: center;

  .col {
    text-align: center;
  }
}