@import './../../variables.scss';

.container {
 @include container(rem(1180), rem(40));
}

.container-small {
  @include container(rem(1032), rem(40));
}

.row {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: rem(40);
  width: 100%;
}

.col {
  @include flex(100%);

  &--4 {
    @include flex(calc(33.333% - rem(25.75)));
  }

  &--6 {
    @include flex(calc(50% - rem(20)));
  }

  &--8 {
    @include flex(calc(67.667% - rem(25.75)));
  }
}

.col-mobile {
  @include respond-from("medium") {
    &--4 {
      @include flex(33.333%);
    }
  
    &--6 {
      @include flex(50%);
    }

    &--12 {
      @include flex(100%);
    }
  }
}