@import './../../variables.scss';

.cta {
  display: inline-block;
  font-weight: 700;
  cursor: pointer;
  @include hover-style;

  &--button {
    border: rem(2) solid $color-white;
    max-width: rem(125);
    text-decoration: none;  
    padding: rem(18);
  }

  &--link {
    color: $color-darkgreen;
    text-decoration: underline;
  }
}