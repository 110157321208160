@import '../../variables.scss';

section.hero--rv {
  padding-top: rem(187);
  padding-bottom: rem(187);

  @include respond-to("medium") {
    padding-top: rem(291.5);
    padding-bottom: rem(291.5);
  }

  p {
    margin-bottom: 1.5rem;

    @include respond-to("small") {
      font-size: rem(20);
      line-height: rem(24);
    }
  }

  .hero__watch_video {
    font-size: 1.25rem;
    font-weight: 700;
    text-decoration: underline;
    margin: -.5rem;
    padding: .5rem;
    border: 0;
    background: none;
    cursor: pointer;
    
    svg {
      margin-left: rem(8);
      position: relative;
      top: rem(3);
      width: rem(20);
      height: rem(20);
    }
  }
}

#plant-health-tool {
  padding-top: rem(50);
  padding-bottom: rem(50);
  
  @include respond-to("small") {
    text-align: center;
  }

  p {
    font-size: rem(15);
    line-height: rem(24);
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-to("small") {
      font-size: rem(16);
    }
  }

  .cta {
    margin-top: 1rem;
  }
}

#defend-your-crops {
  padding-top: 0;
  padding-bottom: 0;
}

#learn-more {
  padding-top: rem(50);
  padding-bottom: rem(50);

  .intro {
    p {
      @include respond-to("small") {
        font-size: rem(20);
        line-height: rem(24);
      }
    }
  }
}


section.hero--brand {
  @include respond-from("medium") {
    padding-top: rem(32);
    padding-bottom: rem(32);
  }
  @include respond-to("medium") {
    height: rem(300);
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .row {
    align-items: center;
  }

  .col {
    :last-child {
      margin-bottom: 0;
    }
  }

  .logo-container {
    padding-top: rem(40);
    padding-bottom: rem(40);
    padding-right: rem(40);
    position: relative;
    background-color: $color-lightgray;
    max-width: fit-content;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -100vw;
      width: 100vw;
      height: 100%;
      background-color: $color-lightgray;
    }
  }

  h2 {
    font-size: rem(24);
    line-height: rem(32);
  }
  p {
    font-size: rem(15);
    line-height: rem(24);
  }

  &.hero--revylok {
    .logo-container {
      background-color: $color-green;
      &:before {
        background-color: $color-green;
      }
    }
  }

  &.hero--surtain {
    .logo-container {
      background-color: $color-red;
      &:before {
        background-color: $color-red;
      }
    }
  }
}