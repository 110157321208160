$rem-base-value-px: 16;

$color-white: #FFFFFF;
$color-red: #C50022;
$color-green: #65AC1E;
$color-darkgreen: #00793A;
$color-lightgray: #F0F0F0;
$color-midgray: #7C7C7C;
$color-darkgray: #333333;
$color-black: #000000;

$breakpoints: (
  'small': 767px,
  'medium': 992px,
  'large': 1200px) !default;

// responsive - breakpoint up
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

// responsive - up to breakpoint
@mixin respond-from($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint) - 1}) {
      @content;
    }
  }
}

@mixin container($width, $gutter) {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter;
  padding-right: $gutter;
  box-sizing: border-box;
}

@mixin center-max($width) {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
}

@mixin flex($width) {
  flex: 0 0 $width;
  max-width: $width;
}

@mixin hover-style {
  &:hover {
    opacity: 0.85;
    transition: opacity 0.15s ease;
  }
}

@function rem($value) {
  $remValue: calc($value / $rem-base-value-px)+rem;
  @return $remValue;
}

$font-family-primary: Helvetica,
  Arial,
  sans-serif;

// helpers
.desktop-only {
  @include respond-from("medium") {
    display: none;
  }
}

.text-center {
  text-align: center;
}

@mixin text-small {
  font-size: rem(12) !important;
  line-height: rem(16) !important;
}

.text-small {
  @include text-small;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: rem(1);
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: rem(1);
}