@import "../../variables.scss";

.wheel-animation {
  &__container {
    width: rem(640);
    height: rem(638); 
    position: relative;
  }

  &__wheel {
    position: absolute;
    width: rem(1161);
    height: rem(1161);
    top: rem(-268);
    left: rem(-510);

    // width: 1161px;
    // height: 1161px;
    // top: -268px;
    // left: -510px;

    transform-origin: center;
    transition: transform 1s ease-out;
    transform: rotate(0);
  }

  &__overlay {
    position: absolute;
    width: rem(1107);
    height: rem(1200);
    top: rem(-275);
    left: rem(-522);
  }

  &__content {
    position: absolute;
    top: 50%;
    left: rem(680);
    right: rem(40);
    transform: translateY(-50%);
    max-width: rem(530);

    h1, h2, h3, h4, h5, h6 {
      @include respond-to("medium") {
        font-size: rem(32);
        line-height: rem(37);
      }
    }

    h1, h2, h3, h4, h5, h6, p {
      color: $color-white;
    }
  }

  @media (min-width: 950px) {
    &__container {
      left: calc(50% - #{rem(640)});

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100vw;
        height: 100%;
        width: 100vw;
        background-color: $color-darkgreen;
        z-index: 1;
      }
    }

    &__content {
      left: calc(50% + #{rem(50)});
    }
  }

  @media (min-width: 633px) and (max-width: 949px) {
    &__container {
      height: rem(950);
    }

    &__content {
      top: rem(650);
      left: rem(80);
      width: rem(450);
      transform: none;
    }
  }

  @media (max-width: 632px) {
    &__container {
      width: rem(375);
      height: rem(651);
      left: calc(100% - #{rem(375)})
    }

    &__container-inner {
      width: 100%;
      height: rem(600);
      overflow: hidden;
    }
  
    &__wheel {
      width: rem(675);
      height: rem(675);
      top: rem(-90);
      left: rem(-319);
    }
  
    &__overlay {
      width: rem(635.34);
      height: rem(691);
      top: rem(-93);
      left: rem(-311);
    }
  
    &__content {
      top: rem(405);
      left: rem(40);
      right: rem(40);
      transform: none;
      max-width: calc(100% - #{rem(140)});
    }
  }

  br {
    @include respond-from("small") {
      display: none;
    }
  }
}