@import "../../variables.scss";

.video-overlay {
  &__link {
    cursor: pointer;
    @include hover-style;
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-black;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;

    &--show {
      display: block;
      z-index: 2;
      opacity: 1;
    }
  }

  &__video {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;

    .vjs-big-play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--autoplay {
      .vjs-big-play-button {
        display: none;
      }
    }
  }

  &__modal-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__modal-close {
    position: absolute;
    top: rem(40);
    right: rem(40);
    border: 0;
    border-radius: 50%;
    width: rem(40);
    height: rem(40);
    background: $color-green;
    cursor: pointer;
    @include hover-style;

    >span {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;

      &:before, &:after {
        content: '';
        position: absolute;
        left: calc(50% - #{rem(1.15)});
        top: calc(50% - #{rem(13)});
        width: rem(2.3);
        height: rem(26);
        background-color: $color-white;
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }
  }
}