@import './../../variables.scss';

.feature-card {
  &__image {
    position: relative;
    z-index: -1;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $color-white;
      background-color: $color-darkgreen;
      max-width: fit-content;
      padding: rem(24);
      margin-top: rem(-40);
      margin-left: rem(40);
    }
  }

  &__body {
    margin-left: rem(40);
    margin-right: rem(40);
    max-width: rem(470);
  }

  &--mobile-extend-left {
    @include respond-from("small") {
      margin-left: rem(-40);
      width: calc(100% + rem(80));

      .feature-card__header {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          max-width: inherit;
          margin-left: rem(60);
          margin-right: 0;
          padding-left: rem(50);
        }
      }

      .feature-card__body {
        margin-left: rem(100);
        margin-right: rem(20);
      }
    }
  }

  &--mobile-extend-right {
    @include respond-from("small") {
      margin-left: rem(20);
      margin-right: rem(-40);

      .feature-card__header {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-left: rem(-60);
          padding-left: rem(50);
        }
      }

      .feature-card__body {
        margin-left: rem(-20);
        margin-right: rem(80);
      }
    }
  }
}