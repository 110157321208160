@import './../../variables.scss';

$breakpoint_tablet: 816px;
$breakpoint_desktop: 1120px;

footer {
  background: $color-lightgray;

  a {
    font-size: rem(14);
    text-decoration: none;
    color: $color-darkgray;

    &:hover {
      color: $color-black;
      font-weight: 500;
      transition: color .15s ease;
    }
  }

  .container {
    @include container(rem(1200), rem(20));
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: rem(20);

    .social {
      @media screen and (max-width: #{$breakpoint_desktop - 1}) {
        margin-top: 1.5rem;
        margin-bottom: 2.75rem;
      }

      @media screen and (min-width: 780px) and (max-width: #{$breakpoint_desktop - 1}) {
        width: 100%;

        >div {
          margin-left: auto;
          margin-right: auto;
          width: fit-content;
        }
      }

      @media screen and (min-width: $breakpoint_desktop) {
        display: flex;
        flex-direction: column;
        grid-row: 1;
        justify-content: space-around;
        margin-top: 2.5rem;
      }

      &__title {
        font-weight: 700;
        font-size: rem(16);
      }
      &__list {
        display: flex;
        margin-top: rem(4);
        position: relative;
        left: rem(-8);

        a {
          display: block;
        }
      }
    }

    .links {
      display: flex;
      flex-wrap: wrap;

      @media screen and (min-width: $breakpoint_tablet) and (max-width: #{$breakpoint_desktop - 1}) {
        width: 100%;
        flex-wrap: nowrap;
      }

      &__col {
        @media screen and (max-width: #{$breakpoint_tablet - 1}) {
          flex: 1 1 50%;
        }

        >ul {
          margin-bottom: rem(16);

          >li {
            margin: rem(4) 0;
            line-height: rem(25);

            @media screen and (min-width: $breakpoint_tablet) {
              margin-left: rem(20);
              margin-right: rem(20);
            }

          }
        }
      }
    }
  }

  .notice {
    justify-content: flex-start;
    padding-top: rem(40);
    padding-bottom: rem(32);

    @media screen and (min-width: $breakpoint_tablet) {
      justify-content: center;
    }

    a {
      font-size: rem(13.2);
      line-height: rem(25);
    }
  }
}