@import './reset';
@import './variables';

/* Global styles */

html {
  font-size: $rem-base-value-px + px;
}

body {
  font-family: $font-family-primary;
}

html, body {
  color: $color-darkgray;
}

h1 {
  font-weight: 700;
  font-size: rem(48);
  line-height: rem(55.2);
  margin-bottom: rem(24);
}

h2 {
  font-size: rem(29);
  font-weight: 700;
  line-height: rem(40);
  margin-bottom: 1rem;

  @include respond-to("medium") {
    font-size: rem(40);
    line-height: rem(46);
  }
}

h3 {
  font-size: rem(24);
  font-weight: 700;
  line-height: rem(27.6);
  margin-bottom: rem(16);
}

p {
  font-size: rem(16);
  line-height: rem(18.4);
  margin-bottom: 1rem;

  &.p--large {
    font-size: rem(20);
    line-height: rem(24);
  }
}

sup {
  vertical-align: super;
  font-size: .67rem;
}

sub {
  vertical-align: sub;
  font-size: .67rem;
}