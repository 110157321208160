@import './../../variables.scss';

.header {
  background: $color-green;
  padding-top: rem(20);
  padding-bottom: rem(20);
  position: sticky;
  top: 0;
  z-index: 2;

  &__container {
    max-width: none;
    padding-left: rem(24);
    padding-right: rem(24);
  }

  &__logoLink, &__logo {
    display: block;
    width: rem(120);
    height: auto;
  }
}